import React from 'react';
import './industries.css';
import technology from '../../assets/icons/industries/Technology.svg';
import telecommutions from '../../assets/icons/industries/Telecommutions.svg';
import oil from '../../assets/icons/industries/Oil.svg';
import travel from '../../assets/icons/industries/travel.svg';
import digital from '../../assets/icons/industries/Digital.svg';
// import nonprofit from '../../assets/icons/industries/Nonprofit.svg';
import elearning from '../../assets/icons/industries/E-Learning.svg';
import marketing from '../../assets/icons/industries/Marketing.svg';
import ecommerce from '../../assets/icons/industries/E-commerce.svg';
import lifestyle from '../../assets/icons/industries/Lifestyle.svg';
import healthcare from '../../assets/icons/industries/Healthcare.svg';
import { Row, Col, Container } from 'react-bootstrap-v5';
const Industries = () => {
  return (
    <div className='mxr__industries mxr__section'>
      <Container>
        <Row>
          <Col>
            <h3 className='mxr__section-title text-center'>INDUSTRIES</h3>
            <ul className='row mxr__industries-icons'>
              <li className='col-lg-2 col-md-2 col-sm-2 mxr__industries-icon_box'>
                <img src={technology} alt='technology' />
                <h4>Technology</h4>
              </li>
              <li className='col-lg-2 col-md-2 col-sm-2 mxr__industries-icon_box'>
                <img src={telecommutions} alt='telecommunication' />
                <h4>Telecommunication</h4>
              </li>
              <li className='col-lg-2 col-md-2 col-sm-2 mxr__industries-icon_box'>
                <img src={oil} alt='oil&gas' />
                <h4>Oil & Gas</h4>
              </li>
              <li className='col-lg-2 col-md-2 col-sm-2 mxr__industries-icon_box'>
                <img src={travel} alt='travel' />
                <h4>Travel & Tourism</h4>
              </li>
              <li className=' col-lg-2 col-md-2 col-sm-2 mxr__industries-icon_box'>
                <img src={digital} alt='digital marketing' />
                <h4>Digital Marketing</h4>
              </li>
            </ul>
            <ul className='row mxr__industries-icons'>
              <li className='col-lg-2 col-md-2 col-sm-2 mxr__industries-icon_box'>
                <img src={healthcare} alt='nonprofit' />
                <h4>Health Care</h4>
              </li>
              <li className='col-lg-2 col-md-2 col-sm-2 mxr__industries-icon_box'>
                <img src={elearning} alt='elearning' />
                <h4>E-Learning</h4>
              </li>
              <li className=' col-lg-2 col-md-2 col-sm-2 mxr__industries-icon_box'>
                <img src={marketing} alt='marketing' />
                <h4>Marketing</h4>
              </li>
              <li className='col-lg-2 col-md-2 col-sm-2 mxr__industries-icon_box'>
                <img src={ecommerce} alt='ecommerce' />
                <h4>E-Commerce</h4>
              </li>
              <li className='col-lg-2 col-md-2 col-sm-2 mxr__industries-icon_box'>
                <img src={lifestyle} alt='lifestyle' />
                <h4>Lifestyle</h4>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Industries;
