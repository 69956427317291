import React, { useState } from 'react';
import { Container, Row, Col, Tab, Tabs } from 'react-bootstrap-v5';
import {
  //  BsHeartPulse, BsBook, BsGraphUpArrow,
  BsCheck,
} from 'react-icons/bs';
import './KeyStats.css';
import health from '../../assets/icons/stats/Healthcare.svg';
import education from '../../assets/icons/stats/Education.svg';
import training from '../../assets/icons/stats/Training.svg';

import healthcareImage from '../../assets/icons/stats/Healthcare-Image.jpg';
import educationImage from '../../assets/icons/stats/Education-Image.jpg';
import trainingImage from '../../assets/icons/stats/Training-Image.jpg';

import healthcareWhite from '../../assets/icons/stats/Healthcare-white.svg';
import educationWhite from '../../assets/icons/stats/Education-white.svg';
import trainingWhite from '../../assets/icons/stats/Traning-white.svg';

const statisticsData = [
  {
    title: 'Healthcare',
    text: [
      'InsightXR provides high-fidelity, immersive VR training modules for industries like healthcare.',
      'This allows healthcare professionals to practice procedures and protocols in a safe and controlled environment without the risk of harming real patients.',
      "Additionally, InsightXR's multi-user collaboration feature enables healthcare teams to train together in the same virtual environment, improving communication and teamwork skills.",
    ],
    icon: health,
    image: healthcareImage,
    whiteIcon: healthcareWhite,
  },
  {
    title: 'Education',
    text: [
      "InsightXR gives today's learners and trainers next-generation educational tools.",
      "The platform's device management capabilities allow educators to easily distribute and manage VR/AR content to students.",
      'The customisation options allow educators to create tailored learning experiences that meet the specific needs of their students.',
    ],
    icon: education,
    image: educationImage,
    whiteIcon: educationWhite,
  },
  {
    title: 'Training',
    text: [
      'InsightXR allows for the creation of immersive training experiences designed for high-risk industries.',
      'The platform boasts a 75% increase in knowledge retention, a 90% increase in engagement with XR training, and a 275% increase in confidence for trainees.',
      "InsightXR's performance assessment and skill retention features allow trainers to track student progress and identify areas where they need additional support.",
      'The platform can also help to reduce training costs by eliminating the need for expensive equipment and travel.',
    ],
    icon: training,
    image: trainingImage,
    whiteIcon: trainingWhite,
  },
];

const KeyStats = () => {
  const [activeKey, setActiveKey] = useState(statisticsData[0].title);

  return (
    <div
      className='mxr__section insightSection'
      style={{ backgroundColor: '#f8faff' }}
    >
      <Container className='mt-5 mb-5'>
        <h4 className='text-center mxr__section-title'>
          Benefits of InsightXR for Various Industries
        </h4>

        <Tabs
          activeKey={activeKey}
          onSelect={(k) => setActiveKey(k)}
          className='mb-3 custom-tabs'
          justify
        >
          {statisticsData.map((stat, index) => (
            <Tab
              eventKey={stat.title}
              key={index}
              title={
                <span
                  style={{
                    backgroundColor: activeKey === stat.title ? '#1292ee' : '',
                    color: activeKey === stat.title ? '#ffffff' : '#000000',
                    padding: '10px',
                    borderRadius: '10px',
                    display: 'inline-block',
                  }}
                >
                  <img
                    src={activeKey === stat.title ? stat.whiteIcon : stat.icon}
                    alt={stat.title}
                    className='statInsightIcon'
                    style={{ width: '66px', height: '66px' }}
                  />
                  <br />
                  <span className='stat-title fs-4'>{stat.title}</span>
                </span>
              }
            >
              <Row className='mt-4'>
                <Col md={6} className='text-center'>
                  <img
                    src={stat.image}
                    alt={stat.title}
                    className='statInsightImage'
                    style={{
                      width: '100%',
                      height: 'auto',
                      borderRadius: '10px',
                    }}
                  />
                </Col>
                <Col>
                  <div className='text-content'>
                    {stat.text.map((paragraph, idx) => (
                      <p
                        key={idx}
                        className='text-start d-flex align-items-start keyStatsPara'
                      >
                        <BsCheck
                          style={{
                            width: '40px',
                            height: '40px',
                            marginRight: '10px',
                            color: '#1292ee',
                          }}
                        />
                        {paragraph}
                      </p>
                    ))}
                  </div>
                </Col>
              </Row>
            </Tab>
          ))}
        </Tabs>
      </Container>
    </div>
  );
};

export default KeyStats;

// import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap-v5';
// import { BsHeartPulse, BsBook, BsGraphUpArrow, BsCheck } from 'react-icons/bs';

// const statisticsData = [
//   {
//     percentage: 'Healthcare',
//     text: [
//       'InsightXR provides high-fidelity, immersive VR training modules for industries like healthcare.',
//       'This allows healthcare professionals to practice procedures and protocols in a safe and controlled environment without the risk of harming real patients.',
//       "Additionally, InsightXR's multi-user collaboration feature enables healthcare teams to train together in the same virtual environment, improving communication and teamwork skills.",
//     ],
//     icon: BsHeartPulse,
//   },
//   {
//     percentage: 'Education',
//     text: [
//       "InsightXR gives today's learners and trainers next-generation educational tools.",
//       "The platform's device management capabilities allow educators to easily distribute and manage VR/AR content to students.",
//       'The customisation options allow educators to create tailored learning experiences that meet the specific needs of their students.',
//     ],
//     icon: BsBook,
//   },
//   {
//     percentage: 'Training',
//     text: [
//       'InsightXR allows for the creation of immersive training experiences designed for high-risk industries.',
//       'The platform boasts a 75% increase in knowledge retention, a 90% increase in engagement with XR training, and a 275% increase in confidence for trainees.',
//       "InsightXR's performance assessment and skill retention features allow trainers to track student progress and identify areas where they need additional support.",
//       'The platform can also help to reduce training costs by eliminating the need for expensive equipment and travel.',
//     ],
//     icon: BsGraphUpArrow,
//   },
// ];

// const KeyStats = () => {
//   return (
//     <div
//       className='mxr__section insightSection'
//       style={{ backgroundColor: '#f8faff' }}
//     >
//       <Container className='mt-5 mb-5'>
//         <h4 className='text-center mxr__section-title'>
//           {' '}
//           What are the key features and benefits of InsightXR for various
//           industries like healthcare, education, and training?
//         </h4>
//         <p className=''>
//           <strong>
//             InsightXR is a comprehensive management platform for VR and AR
//             devices that streamlines the deployment of XR at scale. The platform
//             offers a number of features and benefits for various industries,
//             including healthcare, education, and training.
//           </strong>
//         </p>
//         <Row>
//           <Col className='d-flex justify-content-md-center gap-5'>
//             {statisticsData.map((stat, index) => (
//               <div className='stat-item InsightStat' key={index}>
//                 {/* Render the icon component */}
//                 <stat.icon
//                   className='mb-4 statInsightIcon'
//                   style={{ color: '#1292ee', fontSize: '4rem' }}
//                 />
//                 <h3>{stat.percentage}</h3>
//                 {/* Render each paragraph inside the text array */}
//                 {stat.text.map((paragraph, idx) => (
//                   <p key={idx}>
//                     <BsCheck
//                       style={{
//                         marginRight: '8px',
//                         color: '#1292ee',
//                         fontSize: '2rem',
//                       }}
//                     />

//                     {paragraph}
//                   </p>
//                 ))}
//               </div>
//             ))}
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default KeyStats;

// import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap-v5';
// import { RiGraduationCapFill } from 'react-icons/ri';
// import { FaThumbsUp } from 'react-icons/fa';
// import { FaFaceGrinWide } from 'react-icons/fa6';

// const statisticsData = [
//   {
//     percentage: '75%',
//     text: 'Increase in knowledge retention',
//     icon: RiGraduationCapFill,
//   },
//   {
//     percentage: '90%',
//     text: 'Increase in engagement with XR training',
//     icon: FaThumbsUp,
//   },
//   {
//     percentage: '275%',
//     text: 'Increase in confidence',
//     icon: FaFaceGrinWide,
//   },
// ];

// const KeyStats = () => {
//   return (
//     <div
//       className='mxr__section insightSection'
//       style={{ backgroundColor: '#f8faff' }}
//     >
//       <Container className='mt-5 mb-5'>
//         <Row>
//           <Col className='d-flex justify-content-md-center gap-5'>
//             {statisticsData.map((stat, index) => (
//               <div
//                 className='stat-item InsightStat
//               '
//                 key={index}
//               >
//                 {/* Render the icon component directly */}
//                 <stat.icon
//                   className='mb-4 statInsightIcon'
//                   style={{ color: '#1292ee', fontSize: '4rem' }}
//                 />
//                 <h3>{stat.percentage}</h3>
//                 <p>{stat.text}</p>
//               </div>
//             ))}
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default KeyStats;
