import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';
// import { FaDesktop, FaImage, FaSearch, FaSun } from 'react-icons/fa';
import track from '../../assets/images/track.svg';
import manage from '../../assets/images/manage.svg';
import monitor from '../../assets/images/monitor.svg';
const Partner = () => {
  return (
    <div
      className='mxr__capabilities mxr__section white_bg_point marginCapab'
      style={{ backgroundColor: '#f8faff' }}
    >
      <Container>
        <h3 className='mxr__section-title ' style={{ textAlign: 'center' }}>
          Partner with MXR: Maximise the Potential of Your Immersive Room
        </h3>

        <p>
          When you partner with MXR, you're not just getting a state-of-the-art
          immersive room—you're gaining a comprehensive solution that includes
          ongoing support, content development, and management tools. Our
          InsightXR Platform is an integral part of this package.
        </p>
        <strong>
          InsightXR : Manage and Optimise Your Immersive Environment
        </strong>
        <p>
          The InsightXR Platform is a powerful management tool that allows you
          to:
        </p>
        <Row className='mt-5'>
          <Col md={6} sm={12}>
            <div className='icon_leftside'>
              {/* <FaDesktop size={30} color='#1292ee' /> */}
              <img src={monitor} alt=' monitor' />
            </div>
            <div>
              <h5 className='textUppercase'>Monitor Hardware Performance </h5>
              <p>
                Keep track of all the components in your immersive room and
                ensure they are functioning optimally.
              </p>
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className='icon_leftside'>
              {/* <FaImage size={30} color='#1292ee' /> */}
              <img src={manage} alt='manage ' />
            </div>
            <div>
              <h5 className='textUppercase'> Manage Content and Scenarios </h5>
              <p>
                Easily update and deploy new training scenarios, making sure
                your immersive room stays relevant and effective.
              </p>
            </div>
          </Col>
        </Row>
        <Row className='mt-5'>
          <Col md={6} sm={12} className='researchMargin'>
            <div className='icon_leftside'>
              {/* <FaSearch size={30} color='#1292ee' /> */}
              <img src={track} alt='track analitcs' />
            </div>
            <div>
              <h5 className='textUppercase'>Track Training Analytics</h5>
              <p>
                Gain insights into how your immersive room is being used,
                including user engagement, learning outcomes, and more.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Partner;
