import React, { useEffect, useState, useRef } from 'react';
import './header.css';
import { Container, Nav, Navbar } from 'react-bootstrap-v5';

import Logo from '../../assets/images/mxrLogo.svg';
import { menuItems } from '../../menuItems';
import MenuItems from './MenuItems';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const navbarToggleRef = useRef(null);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  const handleMenuItemClick = () => {
    if (navbarToggleRef.current && window.innerWidth <= 991) {
      navbarToggleRef.current.click();
    }
  };
  // const handleMenuItemClick = () => {
  //   if (navbarToggleRef.current) {
  //     navbarToggleRef.current.click();
  //   }
  // };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div id='wrapper' className='page-wrapper'>
        <div className='mxr__header'>
          <div
            className={`mxr__header-navbar  ${
              isScrolled ? 'scrolled' : 'transparent'
            }`}
          >
            <Navbar expand='lg' variant='light'>
              <Container>
                <Navbar.Brand href='/'>
                  <img
                    src={Logo}
                    className='mxr__header-logo'
                    alt='Meta Extended Reality'
                  />
                </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls='basic-navbar-nav'
                  ref={navbarToggleRef}
                />
                <Navbar.Collapse id='basic-navbar-nav'>
                  <Nav className='me-auto' eventkey='1'>
                    {menuItems.map((menu, index) => {
                      const depthLevel = 0;
                      return (
                        <MenuItems
                          items={menu}
                          key={index}
                          depthLevel={depthLevel}
                          onMenuItemClick={handleMenuItemClick}
                        />
                      );
                    })}
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
