import React from 'react';
import { Row, Col } from 'react-bootstrap-v5';
import Research from '../../assets/images/Research-and-Innovation.jpg';

const About = () => {
  return (
    <div className='mxr__section mxr__partnerSec'>
      <div className='partnership-section'>
        <div className='container'>
          <Row className='justify-content-md-center'>
            <Col lg={5}>
              <img className='mb-4' src={Research} alt='Why Partner with US' />
            </Col>
            <Col lg={7} style={{ alignContent: 'center' }}>
              <h3 className='mxr__section-title textUppercase'>
                Research and Innovation
              </h3>
              <p>
                Any new and innovative ideas are welcomed in our organisation
                and we will provide you the right kind of R&D service which will
                act as a fuel to kick start your new business objective. Meta
                Extended Reality (MXR) is an organisation which not only creates
                technological solutions but also helps & guides people who want
                to develop something of their own. We support and guide you
                throughout your innovative journey by providing tech support,
                R&D engineers and a team of experienced people.
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default About;
