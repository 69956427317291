import React from 'react';
import { Container, Col } from 'react-bootstrap-v5';
import InsightPhoto from '../../assets/images/InsightXrPhoto1.svg';

const PhotoSection = () => {
  return (
    <div className='mxr__section PhotoSection'>
      <Container>
        <Col>
          {/* <h2 className='mb-4 text-center'>
            How does InsightXR address the common challenge of poor knowledge
            retention in training programs?
          </h2> */}
          <h2 className='mb-4 text-center'>
            InsightXR's Approach to Knowledge Retention
          </h2>
          {/* <h4 className='text-center'></h4> */}
          <p className='mb-5 text-center'>
            InsightXR tackles the issue of poor knowledge retention, a common
            problem in training programs where learners often forget a
            significant portion of the content quickly, by using immersive
            training. Here's how InsightXR improves knowledge retention:
          </p>
          <img
            className='InsightPhotoSectionImg'
            src={InsightPhoto}
            alt=''
            style={{}}
          />
        </Col>
      </Container>
    </div>
  );
};

export default PhotoSection;
