import React from 'react';
import './advance2.css';
import { Container, Row, Col } from 'react-bootstrap-v5';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import {
  FaTencentWeibo,
  FaLayerGroup,
  FaIdCardAlt,
  FaFilePowerpoint,
  FaTv,
  FaGlobe,
} from 'react-icons/fa';
import timelines from '../../assets/images/advance_features3.png';

const Advance2 = () => {
  return (
    <div className='mxr__advance2 mxr__section'>
      <Container>
        <h3 className='mxr__section-title textUppercase text-center'>
          Additional Features
        </h3>
        <Row>
          <Col className='vr360advanceImg' md={7} sm={12}>
            <img src={timelines} className='additionalFeatures' alt='pc' />
          </Col>
          <Col md={5} sm={12}>
            <VerticalTimeline>
              <VerticalTimelineElement className='vertical-timeline-element--work verticalPadding'>
                <div className='icon_timeline'>
                  <FaTencentWeibo size={25} color='#1292ee' className='mt-3' />
                </div>
                <div className='advance_Features_heading'>
                  <h4>FAST PRELOAD</h4>
                  <p>Quick loading of panoramic images.</p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement className='vertical-timeline-element--work'>
                <div className='icon_timeline'>
                  <FaLayerGroup size={25} color='#1292ee' className='mt-3' />
                </div>
                <div className='advance_Features_heading'>
                  <h4>MULTI MAPS</h4>
                  <p>Load multiple map layers and view angles.</p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement className='vertical-timeline-element--work'>
                <div className='icon_timeline'>
                  <FaIdCardAlt size={25} color='#1292ee' className='mt-3' />
                </div>
                <div className='advance_Features_heading'>
                  <h4>LIVE SESSIONS</h4>
                  <p>Share virtual tours with video call and chat.</p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement className='vertical-timeline-element--work'>
                <div className='icon_timeline'>
                  <FaFilePowerpoint
                    size={25}
                    color='#1292ee'
                    className='mt-3'
                  />
                </div>
                <div className='advance_Features_heading'>
                  <h4>PRESENTATION</h4>
                  <p>Create storytelling presentations.</p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement className='vertical-timeline-element--work'>
                <div className='icon_timeline'>
                  <FaTv size={25} color='#1292ee' className='mt-3 icon' />
                </div>
                <div className='advance_Features_heading'>
                  <h4>VIRTUAL STAGING</h4>
                  <p>Show before-and-after views in split-screen.</p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement className='vertical-timeline-element--work last'>
                <div className='icon_timeline'>
                  <FaGlobe size={25} color='#1292ee' className='mt-3' />
                </div>
                <div className='advance_Features_heading'>
                  <h4>WEBVR</h4>
                  <p>
                    Experience tours in 3D virtual reality directly in the
                    browser.
                  </p>
                </div>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Advance2;
