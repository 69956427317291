import React from 'react';
// import parternshipImg2 from '../../assets/images/partnership3.png';
// import { FaCheckCircle, FaCheckDouble, FaCheck } from 'react-icons/fa';
import { Row, Col } from 'react-bootstrap-v5';
import offer from '../../assets/All-Images/Immersive-room2.png';
import content from '../../assets/All-Images/content3.jpg';

const Whatis = () => {
  return (
    <div className='mxr__section mxr__partnerSec'>
      <div className='partnership-section'>
        <div className='container'>
          <Row className='justify-content-md-center'>
            <Col lg={7} style={{ alignContent: 'center' }}>
              <h3 className='mxr__section-title textUppercase text-left'>
                What is an Immersive Room?
              </h3>
              {/* <h3 className='textUppercase'>
                Custom Immersive Room Development{' '}
              </h3> */}
              <p>
                An immersive room is a specialised space where advanced
                technology is used to create a 360° interactive environment.
                These rooms are equipped with high-resolution projection
                systems, interactive displays, and integrated audio, all
                designed to engage users in a fully immersive experience.
                Whether you're exploring complex subjects, simulating real-world
                scenarios, or participating in collaborative projects, an
                immersive room provides an unparalleled learning experience.
              </p>
            </Col>

            <Col lg={5}>
              <img src={offer} alt='Why Partner with US' />
            </Col>
          </Row>

          <Row className='justify-content-md-center'>
            <Col lg={5}>
              <img
                className='immersiveWhatIsImage2'
                src={content}
                alt='Why Partner with US'
              />
            </Col>
            <Col lg={7} style={{ alignContent: 'center' }}>
              <h3 className='mxr__section-title textUppercase text-left mt-5'>
                Benefits for Educational Institutions and Training Providers
              </h3>
              <div className='top-left-sec mt-4'>
                {/* <h5 className='textUppercase'>Benefits of Immersive Rooms</h5> */}
                {/* <ul className='mxr__partnershipList' style={{ margin: '10px' }}> */}
                <strong className='whatweLiFont'>
                  Enhanced Learning Outcomes{' '}
                </strong>{' '}
                <p>
                  Immersive rooms create engaging and interactive environments
                  that improve student engagement and retention.
                </p>
                <strong className='whatweLiFont'>Real-World Simulations</strong>{' '}
                <p>
                  Provide students with practical, hands-on experience in a
                  controlled setting, preparing them for the demands of the
                  workplace.
                </p>
                <strong className='whatweLiFont'>Customisable Content </strong>{' '}
                <p>
                  Tailor the immersive experience to align with specific
                  curricula, including T-levels, apprenticeships, and other
                  training programmes.
                </p>
                <strong className='whatweLiFont'>
                  Collaborative Learning{' '}
                </strong>{' '}
                <p>
                  Foster teamwork and innovation through interactive group
                  activities.
                </p>
                <strong className='whatweLiFont'>
                  Gamification and Collective Training{' '}
                </strong>{' '}
                <p>
                  Implement gamified elements to make learning fun and engaging,
                  while also enabling collective training sessions where
                  multiple users can interact, compete, and collaborate within
                  the same virtual environment.
                </p>
                {/* </ul> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Whatis;
